import { FC, PropsWithChildren, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import RootPage from './pages/home/RootPage'
import UserContextProvider from './context/user/ContextProvider'
import Authorize from './pages/login/authorize'
import { useAuth, AuthState } from './hooks/useAuth'
import LoginPage from './pages/login/LoginPage'
import Spinner from './components/input/Spinner'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Footer from './components/Footer'
import BottomInfo from './components/info/BottomInfo'
import * as Sentry from '@sentry/react'
import { setTimezoneAndLocale } from './util/time'
import { ConfigProvider } from 'antd'
import en_GB from 'antd/lib/locale/en_GB'

Sentry.init({
  dsn: 'https://28415f0e508e2782bfd9246115ce0b87@o4506545850941440.ingest.sentry.io/4506733920190464',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: import.meta.env.PROD,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 10 * 1000,
    },
  },
})

const AppContainer = ({ children }: PropsWithChildren) => (
  <div className="w-full h-screen">
    <div className="h-[95%] overflow-auto px-4">{children}</div>
    <Footer />
    <BottomInfo />
  </div>
)

const App: FC = () => {
  const { authState } = useAuth()
  setTimezoneAndLocale()

  const getRoot = useMemo(() => {
    switch (authState) {
      case AuthState.LOADING:
        return <Spinner centerScreen />
      case AuthState.AUTHENTICATED:
        return (
          <UserContextProvider>
            <RootPage />
          </UserContextProvider>
        )
      case AuthState.UNAUTHENTICATED:
        return <LoginPage refresh={() => window.location.reload()} />
      case AuthState.AUTHORIZE:
        return <Authorize />
      default:
        return <div>TaskCtrl</div>
    }
  }, [authState])

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={en_GB}>
        <AppContainer>{getRoot}</AppContainer>
      </ConfigProvider>
    </QueryClientProvider>
  )
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
} else console.error('Could not find root element!')
